<div class="flex min-h-screen">
  <div class="flex-1 flex flex-col">
    <div class="flex-1 flex sm:items-center justify-center">
      <div class="max-w-sm w-full p-4">
        <h2 class="pb-2 text-gray-900 text-3xl leading-3xl font-semibold">Sign up to Step</h2>

        <p class="pb-8 text-gray-500">Get evaluation credits instantly upon signing up and start exploring.</p>

        <form [formGroup]="formGroup" (submit)="onSubmit()" class="flex flex-col gap-2">
          <app-label [control]="formGroup.controls.organizationName" title="Organization" #organizationLabel/>
          <mat-form-field subscriptSizing="dynamic" floatLabel="always">
            <input (focusin)="organizationLabel.focus()" (focusout)="organizationLabel.focusout()" matInput placeholder="Organization" name="organizationName" type="text" formControlName="organizationName" class="relative z-[1]" />

            <mat-hint>If you're not part of an organization, your name will be used</mat-hint>
          </mat-form-field>

          <app-label [required]="true" [control]="formGroup.controls.fullName" title="Full name" #fullNameLabel/>
          <mat-form-field subscriptSizing="dynamic">
            <input (focusin)="fullNameLabel.focus()" (focusout)="fullNameLabel.focusout()" matInput placeholder="Full name" name="fullName" type="text" autocomplete="name" formControlName="fullName" class="relative z-[1]" />

            @if (formGroup.controls.fullName | hasError:'required') {
              <mat-error>Full name is required</mat-error>
            }
          </mat-form-field>

          <app-label [required]="true" [control]="formGroup.controls.email" title="Email" #emailLabel/>
          <mat-form-field subscriptSizing="dynamic">
            <app-svg-icon name="mail-01" matPrefix/>

            <input (focusin)="emailLabel.focus()" (focusout)="emailLabel.focusout()" matInput placeholder="Email" name="email" type="email" autocomplete="email" formControlName="email" class="relative z-[1]" />

            @if (formGroup.controls.email | hasError:'required') {
              <mat-error>Email is required</mat-error>
            }

            @if (formGroup.controls.email | hasError:'email') {
              <mat-error>
                Please enter a valid email address
              </mat-error>
            }
          </mat-form-field>

          <app-label [required]="true" [control]="formGroup.controls.password" title="Password" #passwordLabel/>
          <mat-form-field subscriptSizing="dynamic" class="with-suffix">
            <input
              (focusin)="passwordLabel.focus()"
              (focusout)="passwordLabel.focusout()"
              #passwordInput
              matInput
              placeholder="Password"
              name="password"
              type="password"
              autocomplete="new-password"
              formControlName="password"
              class="relative z-[1]"
            />

            <app-password-visibility [inputElement]="passwordInput" matSuffix/>

            <mat-hint>
              A secure password requires 8 letters that include: special characters, numbers, small and capital letters
            </mat-hint>
            @if (formGroup.controls.password | hasError:'required') {
              <mat-error>Password is required</mat-error>
            }

            @if (formGroup.controls.password | hasError:'minlength') {
              <mat-error> Password is too short </mat-error>
            }
          </mat-form-field>

          <div class="text-gray-500 pb-4 text-sm leading-sm">
            <mat-checkbox formControlName="termsOfUse" class="-ml-07 pr-2 !h-5 peer/checkbox"/>
            <span class="peer-[.ng-invalid.ng-touched]/checkbox:text-error-500">I have read and accept the</span>
            <a
              href="https://step.dev/legal/terms-of-use/"
              target="_blank"
              class="link-0 peer-[.ng-invalid.ng-touched]/checkbox:text-error-500 peer-[.ng-invalid.ng-touched]/checkbox:focus:text-error-900"
            >
              Terms of use
            </a>
          </div>

          <app-button
            [buttonType]="ButtonType.SUBMIT"
            [buttonStyle]="ButtonStyle.BTN_0"
            [buttonPadding]="ButtonPadding.BTN_PADDING_0"
            [fluid]="true"
          >
            Get started
          </app-button>

          <p class="py-6 text-center text-gray-500 text-sm leading-sm">
            Already have an account?
            <a routerLink="/login" class="link-0">Log in</a>
          </p>
        </form>
      </div>
    </div>

    <app-auth-footer/>
  </div>

  <div class="flex-1 hidden lg:block max-h-screen sticky top-0">
    <div class="inset-0 absolute">
      <img src="/assets/images/lake-zug-color.jpg" alt="Lake Zug" class="object-cover w-full h-full" />
    </div>

    <div class="inset-0 absolute">
      <img
        src="/assets/images/snowy-mountains-overlay.png"
        alt="Snowy mountains overlay"
        class="object-cover w-full h-full"
      />
    </div>

    <div class="inset-0 absolute flex items-center justify-center">
      <div class="max-w-lg flex flex-col gap-6 p-8 xl:p-4">
        <a
          routerLink="/login"
          matRipple
          class="text-white rounded-xl bg-primary-800 p-4 self-start text-xl focus-ring-primary-2 hover:bg-primary-600 transition-colors"
        >
          <app-svg-icon name="log-in-02"></app-svg-icon>
        </a>

        <h1 class="text-white text-6xl leading-6xl xl:text-7xl xl:leading-7xl font-medium">Evaluate it risk free!</h1>

        <p class="text-primary-200 text-lg leading-lg xl:text-xl xl:leading-xl">
          Register for Step SaaS's evaluation plan - upgrade any time to create more clusters and run more agents!
        </p>
      </div>
    </div>
  </div>
</div>
