import { Component, DestroyRef, inject } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { RequireVerificationDialogData } from 'src/app/common/dialogs/require-verification-dialog/require-verification-dialog-data.interface';
import { SpinnerService } from 'src/app/common/services/spinner.service';
import {
  ButtonPadding,
  ButtonStyle,
  ButtonType
} from "src/app/common/components/button/button.component";
import { RequireVerificationDialogComponent } from '../../../common/dialogs/require-verification-dialog/require-verification-dialog.component';
import { AccountService } from '../../../common/services/account.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent {
  private _dialog = inject(MatDialog);
  private _formBuilder = inject(UntypedFormBuilder);
  private _accountService = inject(AccountService);
  private _spinnerService = inject(SpinnerService);
  private _destroyRef = inject(DestroyRef);

  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
  readonly formGroup = this._formBuilder.group({
    organizationName: [''],
    fullName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(8)]],
    termsOfUse: ['', Validators.requiredTrue],
  });

  onSubmit(): void {
    // Explicitly mark the checkbox control as touched to show required indication (as it does not happen out-of-the-box)
    this.formGroup.controls.termsOfUse.markAsTouched();

    // Form is invalid - do nothing
    if (this.formGroup.invalid) {
      return;
    }

    this._spinnerService.show();

    let { organizationName, fullName, email, password } = this.formGroup.value;

    if (organizationName === undefined || organizationName === '') {
      organizationName = fullName;
    }

    this._accountService.register({ organizationName, fullName, email, password }).subscribe({
      next: () => {
        this._dialog.open<RequireVerificationDialogComponent, RequireVerificationDialogData>(
          RequireVerificationDialogComponent,
          {
            data: {
              email,
              password,
            },
          }
        );

        this._spinnerService.hide();
      },
      error: () => {
        this._spinnerService.hide();
      },
    });
  }
}
